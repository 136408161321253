// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .main-content {
  height: 100%;
  padding: 0;
}
:host .main-content .main-content-wrap {
  height: 100%;
  padding: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/extra/extra.component.scss","webpack://./../../audit%20module/RedThread/src/app/layouts/extra/extra.component.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;EACA,UAAA;ACFJ;ADII;EACE,YAAA;EACA,UAAA;EACA,WAAA;ACFN","sourcesContent":["@import '../../../assets/sass/variable';\r\n\r\n:host {\r\n  .main-content {\r\n    height: 100%;\r\n    padding: 0;\r\n\r\n    .main-content-wrap {\r\n      height: 100%;\r\n      padding: 0;\r\n      width: 100%;\r\n    }\r\n  }\r\n}\r\n",":host .main-content {\n  height: 100%;\n  padding: 0;\n}\n:host .main-content .main-content-wrap {\n  height: 100%;\n  padding: 0;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
