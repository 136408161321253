import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Template } from '../pages/template/template-items';
import { AuthService, DBService, DashboardService } from '../service'
import { single } from 'rxjs-compat/operator/single';
import { User } from '../model/types';
import { environment } from '../../environments/environment';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../pages/dashboard/dialog/dialog.component'
import { window } from 'rxjs-compat/operator/window';
import { Templates } from '../model/analysis-details.model';


@Injectable({
    providedIn: 'root'
})
export class TemplateService {

    url: string = environment.serverUrl + '/temp-data';

    constructor(private http: HttpClient, private authService: AuthService, private router: Router, public dialog: MatDialog, ) {

    }


    checkservice(data: any) {

        this.http.post(this.url, data, { responseType: 'text' }).subscribe(
            (result) => {
              
                this.openDialog(result)
            },
            (error) => {
                console.log('error', error);
            }
        )
    }

    getService() {
        return this.http.get(this.url)
    }

    // function to get the table data used in TEmplate component.
    getTableData() {
        let params = new HttpParams();
        // params = params.append('table_name', name);
        // params = params.append('analysis_type', analysis_type);
        // params = params.append('analysis_subtype', analysis_subtype);
        return this.http.get(this.url, { params: params });
    }

    sendTableData(t_type, t_desc, t_data) {
        const headers = { 'content-type': 'application/json'}
        const body = JSON.stringify({'type': t_type, 'description': t_desc, 'columns': t_data});
        return this.http.post(this.url, body);
    }

    openDialog(data) {
        this.dialog.open(DialogComponent, { data: { name: data }, disableClose: false });
    }
}
