import { Injectable } from '@angular/core';
import { User } from '../model/types';
import { S3Factory } from '../../utils';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthService} from './auth.service';
import 'rxjs/add/operator/map';
import {Subject} from 'rxjs';
import {SearchResult} from '../model/analysis-details.model';
import {environment} from '../../environments/environment';
declare var TextDecoder;

@Injectable({
  providedIn: 'root'
})
export class DownLoadService {

  private signedInUser: User;
  private region: string;
  private signedUrlExpireSeconds = 60 * 5;
  dataLoaded = new Subject<SearchResult[]>();
  BUCKET: string = environment.bucket;
  graph_variable: string;


  constructor(private http: HttpClient,
              private authService: AuthService) {
    this.region = environment.defaultRegion || 'us-east-2';
  }

  setSignedInUser(user: User) {
    this.signedInUser = user;
  }

  // Upload status updates

  setRegion(region: string) {
    this.region = region;
  }

  listFiles() {
   
    return S3Factory.getS3(this.region).listObjectsV2({
      Bucket: this.BUCKET,
      Prefix: [this.signedInUser.username, this.signedInUser.userId].join('/')
    }).promise();
  }

  getUrl(key: string) {
    return S3Factory.getS3(this.region).getSignedUrl('getObject', {
      Bucket: this.BUCKET,
      Key: key,
      Expires: this.signedUrlExpireSeconds
    });
  }

  async getS3Obj(key: string) {
    try {
      const params = {
        Bucket: this.BUCKET,
        Key: key
      }
      const data = await S3Factory.getS3(this.region).getObject(params).promise();
      const result =  new TextDecoder('utf-8').decode(data.Body);
      return  result
    } catch (e) {
      throw new Error(`Could not retrieve file from S3: ${e.message}`)
    }
 /*   S3Factory.getS3(this.region).getObject({
      Bucket: this.BUCKET,
      Key: key
    }, function (err, data) {
      if (err) { console.log(err, err.stack); } else {
        const string = new TextDecoder('utf-8').decode(data.Body);

       
      }
    })
*/  }






}
