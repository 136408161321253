// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center_div {
  margin-top: 10px;
  margin-left: 50px;
  width: 70%;
}

.invalid-feedback {
  color: #f44336; /* Red */
}

.linkAriadne {
  font-size: 18px;
  float: right;
  color: black;
}

.linkAriadne:hover {
  color: red;
}

.imgAriadne {
  height: 60px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/auth/signup/signup.component.scss","webpack://./../../audit%20module/RedThread/src/app/pages/auth/signup/signup.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,UAAA;ACCF;;ADCA;EACE,cAAA,EAAA,QAAA;ACEF;;ADAA;EACE,eAAA;EACA,YAAA;EACA,YAAA;ACGF;;ADDA;EACE,UAAA;ACIF;;ADFA;EACE,uBAAA;ACKF","sourcesContent":[".center_div{\r\n  margin-top: 10px;\r\n  margin-left: 50px;\r\n  width:70% /* value of your choice which suits your alignment */\r\n}\r\n.invalid-feedback {\r\n  color: #f44336; /* Red */\r\n}\r\n.linkAriadne{\r\n  font-size: 18px;\r\n  float: right;\r\n  color: black;\r\n}\r\n.linkAriadne:hover{\r\n  color: red;\r\n}\r\n.imgAriadne{\r\n  height: 60px !important;\r\n}\r\n",".center_div {\n  margin-top: 10px;\n  margin-left: 50px;\n  width: 70%;\n}\n\n.invalid-feedback {\n  color: #f44336; /* Red */\n}\n\n.linkAriadne {\n  font-size: 18px;\n  float: right;\n  color: black;\n}\n\n.linkAriadne:hover {\n  color: red;\n}\n\n.imgAriadne {\n  height: 60px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
