import { Component, Input, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'awscognito-mfa',
    templateUrl: './mfa.component.html',
    styleUrls: ['./mfa.component.scss']
})
export class MfaComponent implements OnInit {
    @Input() destination: string;
    @Input() onSubmit: (code: string) => void;
    mfaForm: FormGroup;

    constructor(private router: Router, public formBuilder: FormBuilder) {
      

    }
    ngOnInit() {
        this.mfaForm = this.formBuilder.group({
            otp: ['', Validators.required]
        });
    }

    get formControls() { return this.mfaForm.controls; }

}




