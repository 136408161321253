// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linkAriadne {
  font-size: 18px;
  float: right;
  color: black;
}

.linkAriadne:hover {
  color: red;
}

.imgAriadne {
  height: 60px !important;
}

.inputSignin {
  border-top: none;
  border-left: none;
  border-right: none;
  float: left;
}

.floatLeft {
  float: left;
}

.responsive {
  width: 100px;
  max-width: 100%;
  height: auto;
}

.error {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/auth/signin/component.scss","webpack://./../../audit%20module/RedThread/src/app/pages/auth/signin/component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,YAAA;ACCJ;;ADCA;EACI,UAAA;ACEJ;;ADAA;EACI,uBAAA;ACGJ;;ADDA;EACI,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;ACIJ;;ADFA;EACI,WAAA;ACKJ;;ADHA;EACI,YAAA;EACA,eAAA;EACA,YAAA;ACMJ;;ADJA;EACI,eAAA;ACOJ","sourcesContent":[".linkAriadne{\r\n    font-size: 18px;\r\n    float: right;\r\n    color: black;\r\n}\r\n.linkAriadne:hover{\r\n    color: red;\r\n}\r\n.imgAriadne{\r\n    height: 60px !important;\r\n}\r\n.inputSignin{\r\n    border-top: none;\r\n    border-left: none;\r\n    border-right: none;\r\n    float: left;\r\n}\r\n.floatLeft{\r\n    float: left;\r\n}\r\n.responsive {\r\n    width: 100px;\r\n    max-width: 100%;\r\n    height: auto;\r\n}\r\n.error {\r\n    font-size: 12px;\r\n}",".linkAriadne {\n  font-size: 18px;\n  float: right;\n  color: black;\n}\n\n.linkAriadne:hover {\n  color: red;\n}\n\n.imgAriadne {\n  height: 60px !important;\n}\n\n.inputSignin {\n  border-top: none;\n  border-left: none;\n  border-right: none;\n  float: left;\n}\n\n.floatLeft {\n  float: left;\n}\n\n.responsive {\n  width: 100px;\n  max-width: 100%;\n  height: auto;\n}\n\n.error {\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
