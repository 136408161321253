// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center {
  width: 25%;
  margin: 10px auto;
}

.main-div {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nolist {
  list-style: none;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/analysis-details/analysis-details.component.scss","webpack://./../../audit%20module/RedThread/src/app/pages/analysis-details/analysis-details.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,iBAAA;ACCF;;ADEA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;ACCF;;ADCA;EACE,gBAAA;ACEF","sourcesContent":[".center{\r\n  width: 25%;\r\n  margin: 10px auto;\r\n}\r\n\r\n.main-div{\r\n  height: 30vh;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n.nolist {\r\n  list-style: none;\r\n}\r\n",".center {\n  width: 25%;\n  margin: 10px auto;\n}\n\n.main-div {\n  height: 30vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.nolist {\n  list-style: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
