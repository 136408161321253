import {Component, OnInit} from '@angular/core';

import {MainMenuItem} from './main-menu-item';
import {MainMenuService} from './main-menu.service';
import {User} from '../../model/types';
import {AuthService} from '../../service';
import {Router} from '@angular/router';
import {AuthNewService} from '../../service/auth-new.service';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'main-menu',
    templateUrl: 'main-menu.component.html',
    styleUrls: ['main-menu.component.scss']
})
export class MainMenuComponent  {
    mainMenuItems: MainMenuItem[];
    signedInUser: any;

    constructor(private authService: AuthService,
        private AuthService: AuthNewService,
                private router: Router,
                private mainMenuService: MainMenuService) {
    }

    getMainMenuItems(user: Object): void {
        const OBSERVER = {
            next: x => {
                this.mainMenuItems = x;
            }
        };
        this.mainMenuService.getData(user).subscribe(OBSERVER);
    }

    ngOnInit(): void {
        // this.authService.getCurrentUser((err, user: User) => {
        //     this.signedInUser = user;
        //     if (!this.signedInUser || !this.signedInUser.signedIn) {
        //         this.router.navigate(['/extra-layout/signin']);
        //         return;
        //     } else {
        //         this.getMainMenuItems(this.signedInUser.username);
        //     }
        // });
        this.AuthService.getCurrentUser();
        this.AuthService.getCurrentLoginUser.pipe(delay(1000)).subscribe((user: any) => {
            
        this.signedInUser = user;
        if (!this.signedInUser) {
            this.router.navigate(['/extra-layout/signin']);
            return;
        } else {
            this.getMainMenuItems(this.signedInUser.attributes);
        }
        });

    }

    toggle(event: Event, item: any, el: any) {
        event.preventDefault();

        let items: any[] = el.mainMenuItems;

        if (item.active) {
            item.active = false;
        } else {
            for (let i = 0; i < items.length; i++) {
                items[i].active = false;
            }
            item.active = true;
        }
    }
}
