// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/auth/mfa/mfa.component.scss","webpack://./../../audit%20module/RedThread/src/app/pages/auth/mfa/mfa.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;ACCJ","sourcesContent":[".error {\r\n    font-size: 12px;\r\n}",".error {\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
