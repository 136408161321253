import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-field-builder',
  template: `
  <div>
    <div class="form-group" [formGroup]="form">
      <label class="text-lg" [attr.for]="field.desc">
        {{field.desc}}
        <strong class="text-danger" *ngIf="field.required">*</strong>
      </label>
      <div [ngSwitch]="field.type">
        <app-textbox matInput *ngSwitchCase="'text'" [field]="field" [form]="form"></app-textbox>
        <app-dropdown matInput *ngSwitchCase="'dropdown'" [field]="field" [form]="form"></app-dropdown>
        <app-checkbox matInput *ngSwitchCase="'checkbox'" [field]="field" [form]="form"></app-checkbox>
        <app-radio matInput *ngSwitchCase="'radio'" [field]="field" [form]="form"></app-radio>
        <app-file matInput *ngSwitchCase="'file'" [field]="field" [form]="form"></app-file>
        <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid && isDirty">{{field.desc}} is required</div>
      </div>
    </div>
  </div>
  `
})
export class FieldBuilderComponent implements OnInit {
  @Input() field: any;
  @Input() form: any;

  get isValid() { return this.form.controls[this.field.name].valid; }
  get isDirty() { return this.form.controls[this.field.name].dirty; }

  constructor() { }

  ngOnInit() {
    
  }

}
