import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { BehaviorSubject } from 'rxjs';
import { AuthNewService } from '../../../service/auth-new.service';
import { StorageService } from '../../../service/storage.service';

@Component({
  selector: 'app-user-sign-in',
  templateUrl: './user-sign-in.component.html',
  styleUrls: ['./user-sign-in.component.scss']
})
export class UserSignInComponent implements OnInit {

  signinForm!: FormGroup;
  codeForm!: FormGroup;
  submitted = false;
  username!: string;
  password!: string;
  code!: string;
  user!: CognitoUser;
  userAuthenticated = false;
  mfaFlag = false;
  userGroup = new BehaviorSubject<string>('');
  hide = true;
  error: string;

  constructor(public formBuilder: FormBuilder, public router: Router, private authservice: AuthNewService,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.error = ''
    this.signinForm  =  this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.codeForm  =  this.formBuilder.group({
      code: ['', Validators.required]
    });
  }
  get formControls() { return this.signinForm.controls; }
  get formControls1() { return this.codeForm.controls; }

  signInNew() {

    this.submitted = true;
    this.userAuthenticated = false;
      if (this.signinForm.invalid) {
        return;
      }
      

      this.username = this.signinForm.value.username;
      this.password = this.signinForm.value.password;
      this.authservice.signIn(this.username, this.password);
      


      this.authservice.signinError.subscribe((error) => {
        
        if (error) {
          this.submitted = false;
          this.error = 'Username or Password is incorrect.';
        } else {
          this.error = '';
        }
      })
      this.authservice.user.subscribe((user: any) => {
        if (user) {
          if (user.challengeName === 'SMS_MFA') {
            this.mfaFlag = true;
          } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.router.navigate(['/extra-layout/new-password']);
          } else {
            const storedData = this.storageService.get('access');

          if (storedData == 'QA') {
            this.router.navigate(['default-layout/user-details'])
          }
          else
            this.router.navigate(['default-layout/previous-analysis'])
          }
        }
      })
  }

  confirmationCode() {
    this.code = this.codeForm.value.code;
    this.authservice.confirmCode(this.code);
    this.authservice.user.subscribe((newUser: any) => {
      if (newUser.signInUserSession) {
        this.userAuthenticated = true;
        this.user = newUser;
       
      } else {
        console.log('error');
      }
      if (this.userAuthenticated) {
        this.router.navigate(['default-layout/previous-analysis'])
      }
    });
  }
}
