// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.file-row.odd {
  background: #cce5ff;
}

div.file-row {
  padding: 10px 0;
}
div.file-row button {
  margin-left: 5px;
}

.progress {
  background: none;
  border: 1px #ccc solid;
}

.percentTag {
  text-align: right !important;
  color: black;
  align-items: center;
}

.fa-remove {
  display: block;
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/doc-upload/file-upload/file-upload.component.scss","webpack://./../../audit%20module/RedThread/src/app/pages/doc-upload/file-upload/file-upload.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;ACCF;;ADEA;EACE,eAAA;ACCF;ADAE;EACE,gBAAA;ACEJ;;ADEA;EACE,gBAAA;EACA,sBAAA;ACCF;;ADCA;EACE,4BAAA;EACA,YAAA;EACA,mBAAA;ACEF;;ADCA;EACA,cAAA;EACE,YAAA;ACEF","sourcesContent":["div.file-row.odd{\r\n  background: #cce5ff;\r\n}\r\n\r\ndiv.file-row {\r\n  padding: 10px 0;\r\n  button {\r\n    margin-left: 5px;\r\n  }\r\n}\r\n\r\n.progress {\r\n  background: none;\r\n  border: 1px #ccc solid;\r\n}\r\n.percentTag{\r\n  text-align: right !important;\r\n  color: black;\r\n  align-items: center;\r\n}\r\n\r\n.fa-remove{\r\ndisplay: block;\r\n  float: right;\r\n}","div.file-row.odd {\n  background: #cce5ff;\n}\n\ndiv.file-row {\n  padding: 10px 0;\n}\ndiv.file-row button {\n  margin-left: 5px;\n}\n\n.progress {\n  background: none;\n  border: 1px #ccc solid;\n}\n\n.percentTag {\n  text-align: right !important;\n  color: black;\n  align-items: center;\n}\n\n.fa-remove {\n  display: block;\n  float: right;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
