// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chartCards {
  background-color: lightgoldenrodyellow;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/small-molecule/graph/graph.component.scss","webpack://./../../audit%20module/RedThread/src/app/pages/small-molecule/graph/graph.component.scss"],"names":[],"mappings":"AAAA;EACI,sCAAA;ACCJ","sourcesContent":[".chartCards {\r\n    background-color: lightgoldenrodyellow;\r\n}",".chartCards {\n  background-color: lightgoldenrodyellow;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
