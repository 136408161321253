import { Injectable } from '@angular/core';
import {
    Dashboard,
    SearchResult,
    Summary,
    AnalysisData,
    PreviousAnalysis, PreviousAnalysisData, AnalysisKey, AuthLog, Logs, AWSResponse, RDSAnalysisData, NewTable, columnsResult,
} from '../model/analysis-details.model';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Table } from '../model/small-molecule.model';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { StorageService } from './storage.service';


@Injectable({
    providedIn: 'root'
})
export class DBService {
    AnalysisURL: string = environment.serverUrl + '/analysis-data';
    AuthURL: string = environment.serverUrl + '/auth-data';
    RDSAnalysisURL: string = environment.serverUrl + '/rds_data';
    ValidationURL: string = environment.serverUrl + '/initiate-analysis';
    ColumnNamesURL: string = environment.serverUrl + '/get-tables-data';
    public searchResults: PreviousAnalysis[] = [];
    public recordedLogs: AuthLog[] = [];



    singleSearch = new Subject<SearchResult>();
    authLogs = new Subject<Logs>();
    userSearch = new Subject<PreviousAnalysis[]>();
    rdsAnalysisData = new Subject<RDSAnalysisData[]>();
    summary = new Subject<Summary>();
    columnsSearch = new Subject();




    constructor(private http: HttpClient,
        private storageService:StorageService,
        private authService: AuthService) {
        this.userSearch.subscribe(
            (data: PreviousAnalysis[]) => {
               
                this.searchResults = data;
            }
        );
        // this.getDashBoardData();
    }

    getSummary(userId) {
        let params = new HttpParams();
        params = params.append('userId', userId);
        params = params.append('analysisId', null);
        params = params.append('type', 'summary');
        params = params.append('path', null);

        this.http.get(this.AnalysisURL, { params: params }).map(res => <Summary>res).subscribe(
            (data) => {
                this.summary.next(data);
            },
            (error) => {
                console.log('Error in calling data from backend', error);
            }
        );

    }


    getUserAnalysis(userId) {
        let params = new HttpParams();
        params = params.append('userId', userId);
        params = params.append('analysisId', null);
        params = params.append('type', null);
        params = params.append('path', null);


        this.http.get(this.AnalysisURL, { params: params }).map(res => <PreviousAnalysisData>res).subscribe(
            (data) => {
                this.userSearch.next(data.data);
            },
            (error) => {
                console.log('Error in calling data from backend', error);
            }
        );

    }

    // getAnalysis(userId, date=null, path=null) {
    //     let params = new HttpParams();
    //     params = params.append('userId', userId);
    //     params = params.append('date', date);
    //     params = params.append('analysisId', null);
    //     params = params.append('type', 'audit');
    //     params = params.append('path', path);

    //     this.http.get(this.AnalysisURL, {params: params}).map(res => <PreviousAnalysisData>res).subscribe(
    //         (data) => {
    //             // console.log('data', data);
    //             this.userSearch.next(data.data);
    //             sessionStorage.setItem('analysis-data', JSON.stringify(data.data));
    //         },
    //         (error) => {
    //             console.log('Error in calling data from backend', error);
    //         }
    //     );

    // }


    getAnalysis(access, startdate = 'none', enddate = 'none',auditFlag) {
        let params = new HttpParams();
        params = params.append('userId', this.storageService.get('username'));
        params = params.append('organization', this.storageService.get('organization'));
        params = params.append('group', this.storageService.get('group'));
        params = params.append('access', access);
        params = params.append('startdate', startdate);
        params = params.append('enddate', enddate);
        params = params.append('auditFlag', auditFlag);
      
        access=access.toLowerCase()
        if (access === 'ga' || access === 'su'|| access==='qa' || access==='pi') {
            this.http.get(this.RDSAnalysisURL, { params: params }).map(res => <RDSAnalysisData[]>res).subscribe(
                (data) => {
                    JSON.stringify(data)
                    this.rdsAnalysisData.next(data);
                    
                },
                (error) => {
              console.log(error)
                }
            );
        }
     else {
            console.log("Unauthorized User")
        }

    }

    getAnalysisDetails(userName, analysisId) {
        let params = new HttpParams();
        params = params.append('userId', userName);
        params = params.append('analysisId', analysisId);
        params = params.append('type', null);
        params = params.append('path', null);

        this.http.get(this.AnalysisURL, { params: params }).map(res => <AnalysisData>res).subscribe(
            (data) => {

                this.singleSearch.next(data.data);
            },
            (error) => {
                console.log('Error in calling data from backend', error);
            }
        );

        // const URL = 'assets/data/analysis_response_data.json';
        //
        // this.http.get(<any>URL).subscribe(
        //     (data) => {
        //         console.log(data);
        //         this.singleSearch.next(<any>data);
        //     },
        //     (error) => {
        //         console.log(error)
        //     }
        // );
    }

    getColumnNames(process_type, table_ids) {
        let params = new HttpParams();
        params = params.append('process_type', process_type)
        params = params.append('table_ids', JSON.stringify(table_ids));
      
        this.http.get(this.ColumnNamesURL, { params: params }).pipe(res => res).subscribe(
            (data) => {

                this.columnsSearch.next(data)
            },
            (error) => {
                console.log('Error in calling data from backend', error);
            }
        );



    }

    submitColumnsData(analysisDetails) {
    
        return this.http.post(this.AnalysisURL, analysisDetails)
    }

    submitTableData(tableArray: Table[], userId, analysisId) {

        const selectedTables = this.filerTable(tableArray);
        const updateObj = {
            'userId': userId,
            'analysisId': analysisId,
            'analysisStatus': 'TableSelected',
            'selectedTable': JSON.stringify(selectedTables)
        }

        // const AnalysisURL = 'assets/data/input.json';
        // return this.http.get(AnalysisURL);
        return this.http.post(this.AnalysisURL, updateObj)
    }

    filerTable(tableArray: Table[]) {
        const result: any = [];
        for (const table of tableArray) {
            if (table.valid) {
                const obj = {
                    'analyte_name': table.analyte_name,
                    'table_type': table.table_type,
                    'table_index': table.table_index,
                    'table_title': table.table_title,
                    'analysis_type': table.analysis_type,
                    'tb_title': table.tb_title,
                    'table_subtype': table.table_subtype
                }
                result.push(obj)
            }
        }
        return result
    }

    public getDashBoardData(): Dashboard[] {
        const dashBoardObj: Dashboard[] = [];
        for (const obj of this.searchResults) {
            const analysisDetails: AnalysisKey = JSON.parse('obj.key')
            const dbobj: Dashboard = {
                'userId': obj.userId,
                'analysisDate': new Date(parseInt(obj.analysisId, 10)),
                'analyteNames': this.getAnalyteNames(analysisDetails),
                'analysisType': analysisDetails.analysisType,
                'analysisSubtype': analysisDetails.analysisSubtype,
                'projectCode': 'analysisDetails.projectCode',
                // 'projectCodeMV': 'analysisDetails.projectCodeMV'
            }
            dashBoardObj.push(dbobj)
        }
        return dashBoardObj
    }

    public getSAProjectCodes(): any[] {
        const mySet = new Set();
        const dashboardObjs = this.getDashBoardData();
        for (const obj of dashboardObjs) {
            if (obj.projectCode) {
                mySet.add(obj.projectCode)
            }
        }
        return Array.from(mySet.values());
    }

    public getMVProjectCodes(): any[] {
        const mySet = new Set();
        const dashboardObjs = this.getDashBoardData();
        for (const obj of dashboardObjs) {
            // if (obj.projectCodeMV) {
            //     mySet.add(obj.projectCodeMV)
            // }
        }
        return Array.from(mySet.values());
    }

    public getAnalyteNames(analysisDetails) {
        const analytes: string[] = [];
        for (const analyte of analysisDetails.analytes) {
            analytes.push(analyte.analyteName)
        }
        return analytes
    }

    // Authentication
    recordAuthEvent(event: string) {
        const data = { 'event': event };
        return this.http.post(this.AuthURL, data);
    }


    getAuthData(userId, date, path: string) {
       
        let params = new HttpParams();
        params = params.append('userId', userId);
        params = params.append('date', date);
        params = params.append('path', path);
        this.http.get(this.AuthURL, { params: params }).map(res => <Logs>res).subscribe(
            (logs) => {
              
                this.authLogs.next(logs)
                sessionStorage.setItem('login-data', JSON.stringify(logs));
            },
            (error) => {
                console.log('Error in calling data from backend', error);
            }
        );

    }

    verifyAnalysis(analysisKeyObj: RDSAnalysisData): Observable<AWSResponse> {
        let params = new HttpParams();
        params = params.append('username', analysisKeyObj.user_id);
        params = params.append('group', analysisKeyObj.group_id);
        params = params.append('filename', analysisKeyObj.file_name);
        params = params.append('analysisId', analysisKeyObj.analysis_id)
        params = params.append('organization', analysisKeyObj.organization_id)
        params = params.append('analysis_type', analysisKeyObj.analysis_type)
        params = params.append('project_code', analysisKeyObj.project_code)
      
        return this.http.post<AWSResponse>(this.ValidationURL,
            { params: params }).pipe(
                tap(data => console.log('AWSResponse in verify Analysis: ', JSON.stringify(data))),
                catchError(this.handleError)
            );
    }
    private handleError(err: HttpErrorResponse): Observable<never> {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(() => errorMessage);
    }

}
